import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';

const notify = () => toast.error('У вас уже есть чат с этим пользователем!');
const notifySuccess = () => toast.success('Чат создан успешно!');

function CreateChat() {
    const [friends, setFriends] = useState([]);
    const [filteredFriends, setFilteredFriends] = useState([]);
    const [selectedFriendId, setSelectedFriendId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { isLoggedIn } = useContext(AuthContext);

    useEffect(() => {
        const fetchFriends = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setFriends(response.data);
                setFilteredFriends(response.data);
            } catch (error) {
                console.error('There was an error fetching the friends!', error);
            }
        };

        if (isLoggedIn) {
            fetchFriends();
        }
    }, [isLoggedIn]);

    const handleCreateChat = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/chats`, {
                user2_id: selectedFriendId,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setSelectedFriendId('');
            setIsModalOpen(false);
            notifySuccess();
        } catch (error) {
            if (error.response && error.response.data.error) {
                console.error('Error:', error.response.data.error);
                alert(error.response.data.error);
            } else {
                console.error('There was an error creating the chat!', error);
                notify();
            }
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFilteredFriends(friends.filter(friend => friend.friend.name.toLowerCase().includes(e.target.value.toLowerCase())));
    };

    if (!isLoggedIn) {
        return <p>Пожалуйста, войдите в свою учетную запись.</p>;
    }

    return (
        <div className="flex justify-end">
            <button onClick={() => setIsModalOpen(true)} className="text-2xl dark:text-neutral-100 hover:text-violet-600 dark:hover:text-violet-600 transition duration-300">
                <i className="fas fa-plus"></i>
            </button>

            <AnimatePresence>
                {isModalOpen && (
                    <motion.div
                        className="fixed inset-0 bg-black/50 flex justify-center items-center z-50 backdrop-blur-sm"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white dark:bg-neutral-800 p-6 rounded-lg shadow-xl max-w-lg w-full relative"
                            initial={{ scale: 0.9, y: 20 }}
                            animate={{ scale: 1, y: 0 }}
                            exit={{ scale: 0.9, y: 20 }}
                            transition={{ type: 'spring', stiffness: 200, damping: 20 }}
                        >
                            <button onClick={() => setIsModalOpen(false)} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white">
                                <i className="fas fa-times text-2xl"></i>
                            </button>
                            <h2 className="text-3xl font-extrabold text-gray-800 mb-6 dark:text-white">Создание чата</h2>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Поиск друзей..."
                                className="w-full mb-4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-violet-500 dark:bg-neutral-700 dark:text-white dark:border-neutral-600"
                            />
                            <div className="grid grid-cols-2 gap-4 max-h-60 overflow-y-auto">
                                {filteredFriends.length === 0 ? (
                                    <div className="text-center text-gray-500 dark:text-gray-400 col-span-2">
                                        Нет результатов
                                    </div>
                                ) : (
                                    filteredFriends.map(friend => (
                                        <motion.div
                                            key={friend.id}
                                            className={`flex items-center p-4 rounded-lg cursor-pointer border transition-transform duration-200 transform hover:scale-105 ${selectedFriendId === friend.friend.id ? 'border-violet-500 bg-violet-100 dark:bg-violet-700' : 'border-gray-300 dark:border-gray-600'}`}
                                            onClick={() => setSelectedFriendId(friend.friend.id)}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: 20 }}
                                        >
                                            <img
                                                src={friend.friend.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${friend.friend.avatar}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s'}
                                                alt="Avatar"
                                                className="w-12 h-12 rounded-full mr-4 object-cover"
                                            />
                                            <div className="flex flex-col">
                                                <p className="font-semibold text-gray-800 dark:text-white">{friend.friend.name}</p>
                                            </div>
                                        </motion.div>
                                    ))
                                )}
                            </div>
                            <div className="flex justify-end mt-6">
                                <button
                                    onClick={handleCreateChat}
                                    className={`bg-violet-500 hover:bg-violet-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ${!selectedFriendId ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={!selectedFriendId}
                                >
                                    Создать чат
                                </button>
                                <button
                                    onClick={() => setIsModalOpen(false)}
                                    className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ml-2"
                                >
                                    Отмена
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default CreateChat;
