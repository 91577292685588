import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react'; // Компонент для генерации QR-кода
import { AuthContext } from '../context/AuthContext';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function QrLoginDesktop() {
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [qrToken, setQrToken] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    // Генерация QR-кода при загрузке страницы
    useEffect(() => {
        const generateQrCode = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/qr/generate`);
                setQrToken(response.data.qr_code_token);
                setQrCodeUrl(response.data.qr_code_url);
            } catch (error) {
                toast.error('Ошибка при генерации QR-кода');
                console.error(error);
            }
        };
        generateQrCode();
    }, []);

    // Проверка статуса QR-кода каждые 3 секунды
    useEffect(() => {
        if (qrToken) {
            const interval = setInterval(async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/qr/status/${qrToken}`);
                    if (response.data.status === 'success') {
                        localStorage.setItem('token', response.data.token);
                        login(response.data.token);
                        setIsLoggedIn(true);
                        clearInterval(interval);
                        navigate('/');
                    }
                } catch (error) {
                    console.error('Ошибка проверки статуса QR-кода', error);
                }
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [qrToken, login, navigate]);

    if (isLoggedIn) {
        return <p>Вы успешно вошли в систему!</p>;
    }

    return (
        <div className="qr-login-container">
            <h2 className="text-2xl font-semibold mb-4">Вход через QR-код</h2>
            {qrCodeUrl ? (
                <QRCodeCanvas value={qrCodeUrl} size={200} />
            ) : (
                <p>Генерация QR-кода...</p>
            )}
        </div>
    );
}

export default QrLoginDesktop;
