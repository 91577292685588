import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import { motion, useAnimation } from 'framer-motion';
import { AuthContext } from '../context/AuthContext';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data/sets/15/apple.json';
import sendSound from '../assets/sendMessage.mp3';
import ForwardMessageModal from './ForwardMessageModal';
import WallpaperModal from './WallpaperModal';
import ConfirmationModal from './ConfirmationModal';
import {format, parseISO, isToday, isYesterday, isThisWeek} from 'date-fns';
import { ClipLoader } from 'react-spinners';
import Loader from "./Loader";
import useWindowSize from "../hooks/useWindowSize";
import {ru} from "date-fns/locale";
import Pusher from 'pusher-js';
import {Link} from "react-router-dom";
import MessageDateSeparator from "./chat/MessageDateSeparator";
import OnlineStatus from "./OnlineStatus";
import toast from "react-hot-toast";
import NoMessage from "./chat/NoMessage";
import debounce from 'lodash.debounce';
const reactionsList = ['👍', '❤️', '😂', '😮', '😢', '👎'];

function Chat({ chatId, onNewMessage, onCloseChat, onlineStatus, initialStatus, initialLastSeen }) {
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef(messages)
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const textareaRef = useRef(null);
  const [editMessageId, setEditMessageId] = useState(null);
  const [chatPartner, setChatPartner] = useState('');
  const [chatPartnerAvatar, setChatPartnerAvatar] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  const [messageContextMenu, setMessageContextMenu] = useState({ visible: false, x: 0, y: 0, messageId: null });
  const [replyToMessage, setReplyToMessage] = useState(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);
  const messagesEndRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const contextMenuRef = useRef(null);
  const messageContextMenuRef = useRef(null);
  const chatContainerRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  const controls = useAnimation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageId, setMessageId] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const [wallpaper, setWallpaper] = useState('');
  const [showWallpaperModal, setShowWallpaperModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { width } = useWindowSize();
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const { user } = useContext(AuthContext);
  const userId = user ? user.id : null;
  const touchTimer = useRef(null);


  const playSendSound = useCallback(() => {
    const audio = new Audio(sendSound);
    audio.play();
  }, []);

  useEffect(() => {
    const savedWallpaper = localStorage.getItem('chatWallpaper');
    if (savedWallpaper) {
      setWallpaper(savedWallpaper);
    }
  }, []);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    if (textarea.scrollHeight > 150) {
      textarea.style.height = '150px';
      textarea.style.overflowY = 'auto';
    } else {
      textarea.style.overflowY = 'hidden';
    }
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [content]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && handleSubmit) {
      if (e.shiftKey) {
      } else {
        e.preventDefault();
        if (content.trim()) {
          handleSubmit(e);
          setContent('');
        }
      }
    }
  };

  const toggleMessageSelection = (messageId) => {
    setSelectedMessages((prevSelected) =>
        prevSelected.includes(messageId)
            ? prevSelected.filter((id) => id !== messageId)
            : [...prevSelected, messageId]
    );
  };

  const openImageModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setModalImageSrc(null);
  };

  const handleThreeDotClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { clientX, clientY } = e;
    setContextMenu({
      visible: true,
      x: clientX,
      y: clientY,
    });
    setMessageContextMenu({ visible: false, x: 0, y: 0, messageId: null });
  };

  function handleMessageContextMenu(e, messageId) {
    e.preventDefault();
    e.stopPropagation();

    setHighlightedMessageId(messageId);
    setMessageContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      messageId,
    });
  }

  useEffect(() => {
    if (messageContextMenu.visible && messageContextMenuRef.current) {
      const windowHeight = window.innerHeight;
      const clickY = messageContextMenu.y;
      const menuHeight = messageContextMenuRef.current.offsetHeight;

      const isMenuTooLow = clickY + menuHeight > windowHeight;

      if (isMenuTooLow) {
        setMessageContextMenu((prevState) => ({
          ...prevState,
          y: clickY - menuHeight,
        }));
      }
    }
    if (messageContextMenu.visible) {
      controls.start({ opacity: 1, scale: 1 });
    } else {
      controls.start({ opacity: 0, scale: 0.9 });
    }
  }, [messageContextMenu.visible, messageContextMenu.y]);

  const scrollToBottom = (smooth = true) => {
    messagesEndRef.current?.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
  };

  // Check if the user is at the bottom of the chat
  const isAtBottom = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      return scrollHeight - scrollTop - clientHeight <= 10; // 10px tolerance
    }
    return false;
  };

  // Debounced scroll handler to reduce frequent state updates
  const handleScroll = debounce(() => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const atBottom = scrollHeight - scrollTop - clientHeight <= 10;

      // Update scrolling state only when necessary
      if (atBottom !== isUserScrolling) {
        setIsUserScrolling(!atBottom);
      }
    }
  }, 100); // Debounce interval: 100ms

  // Automatically scroll to the bottom on new messages
  useEffect(() => {
    if (!isUserScrolling) {
      scrollToBottom(true); // Use smooth scrolling for better animation
    }
  }, [messages]); // Dependency on `messages`

  // Attach the scroll event listener
  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isUserScrolling]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (files.length + selectedFiles.length > 10) {
      alert('Вы можете выбрать максимум 10 фотографий.');
      return;
    }
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    // Инициализация Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: 'eu',
      encrypted: true,
    });

    const channel = pusher.subscribe(`chat_${chatId}`);

    channel.bind('new_message', (data) => {
      const newMessage = data.message;

      if (!messagesRef.current.some((msg) => msg.id === newMessage.id)) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        if (!isUserScrolling.current) {
          scrollToBottom();
        }
      }
    });

    return () => {
      pusher.unsubscribe(`chat_${chatId}`);
    };
  }, [chatId, scrollToBottom]);



  useEffect(() => {
    const fetchChatData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/chats/${chatId}/messages`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedMessages = await Promise.all(
            response.data.messages.map(async (message) => {
              if (message.post_id) {
                const postResponse = await axios.get(`${process.env.REACT_APP_API_URL}/posts/${message.post_id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                message.post = postResponse.data;
              }
              return message;
            })
        );

        setMessages(fetchedMessages);

        const userId = parseInt(localStorage.getItem('user_id'), 10);
        const partner = response.data.user1.id === userId ? response.data.user2 : response.data.user1;
        setChatPartner(partner.name);
        setChatPartnerAvatar(
            partner.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${partner.avatar}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s'
        );

        if (!isUserScrolling) {
          scrollToBottom();
        }
      } catch (error) {
        console.error('There was an error fetching the chat data!', error);
      } finally {
        setLoading(false);
      }
    };

    if (isLoggedIn) {
      fetchChatData();
    }
  }, [isLoggedIn, chatId, isUserScrolling]);

  useEffect(() => {
    if (isAtBottom()) {
      scrollToBottom();
    }
  }, [messages]);

  const handleClickOutside = useCallback(
      (event) => {
        if (
            emojiPickerRef.current &&
            !emojiPickerRef.current.contains(event.target) &&
            !emojiButtonRef.current.contains(event.target)
        ) {
          setShowEmojiPicker(false);
        }
        if (
            contextMenuRef.current &&
            !contextMenuRef.current.contains(event.target)
        ) {
          setContextMenu({ visible: false, x: 0, y: 0 });
        }
        if (
            messageContextMenuRef.current &&
            !messageContextMenuRef.current.contains(event.target)
        ) {
          setMessageContextMenu({ visible: false, x: 0, y: 0, messageId: null });
          setHighlightedMessageId(null);
        }
      },
      []
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        onCloseChat();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    chatContainerRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      chatContainerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!content.trim() && files.length === 0) {
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert("Токен не найден! Пожалуйста, войдите в систему.");
      return;
    }

    const formData = new FormData();
    formData.append('content', content);
    files.forEach((file) => {
      formData.append('attachment', file);
    });
    formData.append('reply_to', replyToMessage ? replyToMessage.id : '');

    try {
      let response;

      if (editMessageId) {
        response = await axios.put(
            `${process.env.REACT_APP_API_URL}/messages/${editMessageId}`,
            { content },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        setMessages((prevMessages) =>
            prevMessages.map((m) => (m.id === editMessageId ? response.data : m))
        );
        setEditMessageId(null);
      } else {
        response = await axios.post(
            `${process.env.REACT_APP_API_URL}/chats/${chatId}/messages`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              },
            }
        );

        setMessages((prevMessages) => [...prevMessages, response.data]);
        onNewMessage(chatId, response.data);
        scrollToBottom();
        playSendSound();
      }

      setContent('');
      setFiles([]);
      setReplyToMessage(null);
      setPreviewImage(null);
    } catch (error) {
      console.error('Ошибка при отправке сообщения!', error);
      toast.error("Произошла ошибка при отправке сообщения!");
    }
  };



  const handleCancelEdit = () => {
    setEditMessageId(null);
    setContent('');
    setReplyToMessage(null);
  };

  const handleReplyToMessage = (messageId) => {
    const message = messages.find((m) => m.id === messageId);
    if (message) {
      setReplyToMessage(message);
      setMessageContextMenu({ visible: false, x: 0, y: 0, messageId: null });
      setHighlightedMessageId(null);
    }
  };

  const addEmoji = (emoji) => {
    setContent((prevContent) => prevContent + emoji.native);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  const formatMessageTime = (timestamp) => {
    return format(parseISO(timestamp), 'HH:mm');
  };

  const formatMessageDate = (timestamp) => {
    const date = parseISO(timestamp);

    if (isToday(date)) {
      return 'Сегодня';
    } else if (isYesterday(date)) {
      return 'Вчера';
    } else if (isThisWeek(date)) {
      return `В ${format(date, 'EEEE', { locale: ru })}`;
    } else {
      const formattedDate = format(date, 'dd MMMM', { locale: ru });
      const isSameYear = format(date, 'yyyy') === format(new Date(), 'yyyy');
      return isSameYear ? formattedDate : `${formattedDate} ${format(date, 'yyyy')}`;
    }
  };

  const groupedMessages = messages.reduce((acc, message) => {
    const date = formatMessageDate(message.created_at);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});

  const handleEditMessage = (messageId) => {
    const message = messages.find((m) => m.id === messageId);
    if (message) {
      setContent(message.content);
      setEditMessageId(messageId);
      setMessageContextMenu({ visible: false, x: 0, y: 0, messageId: null });
      setHighlightedMessageId(null);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/messages/${messageId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessages((prevMessages) =>
          prevMessages.filter((m) => m.id !== messageId)
      );
    } catch (error) {
      console.error('There was an error deleting the message!', error);
    }
    setMessageContextMenu({ visible: false, x: 0, y: 0, messageId: null });
    setHighlightedMessageId(null);
  };

  const handleReaction = async (messageId, reaction) => {
    const userId = parseInt(localStorage.getItem('user_id'), 10);
    const messageIndex = messages.findIndex((m) => m.id === messageId);
    const message = messages[messageIndex];

    let updatedReactions = { ...message.reactions } || {};

    const currentUserReaction = Object.keys(updatedReactions).find((reactionType) =>
        updatedReactions[reactionType].includes(userId)
    );

    if (currentUserReaction) {
      if (currentUserReaction === reaction) {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(
              `${process.env.REACT_APP_API_URL}/messages/${messageId}/reactions`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                data: { reaction },
              }
          );

          updatedReactions[currentUserReaction] = updatedReactions[currentUserReaction].filter((id) => id !== userId);
          if (updatedReactions[currentUserReaction].length === 0) {
            delete updatedReactions[currentUserReaction];
          }
        } catch (error) {
          console.error('Ошибка при удалении реакции!', error);
          return;
        }
      } else {
        updatedReactions[currentUserReaction] = updatedReactions[currentUserReaction].filter((id) => id !== userId);
        if (updatedReactions[currentUserReaction].length === 0) {
          delete updatedReactions[currentUserReaction];
        }
        if (!updatedReactions[reaction]) {
          updatedReactions[reaction] = [];
        }
        updatedReactions[reaction].push(userId);
      }
    } else {
      if (!updatedReactions[reaction]) {
        updatedReactions[reaction] = [];
      }
      updatedReactions[reaction].push(userId);
    }

    const updatedMessage = {
      ...message,
      reactions: updatedReactions,
    };

    const updatedMessages = [...messages];
    updatedMessages[messageIndex] = updatedMessage;

    setMessages(updatedMessages);
    setHighlightedMessageId(false);

    if (!currentUserReaction || currentUserReaction !== reaction) {
      try {
        const token = localStorage.getItem('token');
        await axios.post(
            `${process.env.REACT_APP_API_URL}/messages/${messageId}/reactions`,
            { reaction },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            }
        );
      } catch (error) {
        console.error('Ошибка при обновлении реакции!', error);
      }
    }
  };


  const handleForwardButtonClick = (id) => {
    setMessageId(id);
    setIsModalVisible(true);
  };

  const handleForwardMessage = async (chatId) => {
    const token = localStorage.getItem('token');

    try {
      if (Array.isArray(messageId)) {
        await Promise.all(
            messageId.map(async (id) => {
              await axios.post(
                  `${process.env.REACT_APP_API_URL}/messages/forward`,
                  { message_id: id, chat_id: chatId },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
              );
            })
        );
      } else {
        // Пересылаем одно сообщение
        await axios.post(
            `${process.env.REACT_APP_API_URL}/messages/forward`,
            { message_id: messageId, chat_id: chatId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );
      }

      setIsModalVisible(false);
      setMessageId(null);
      setSelectedMessages([]);
    } catch (error) {
      console.error('Ошибка при пересылке сообщений!', error);
    }
  };

  const handleForwardSelectedMessages = () => {
    if (selectedMessages.length > 0) {
      setMessageId(selectedMessages);
      setIsModalVisible(true);
    }
  };

  const handleChangeWallpaper = (newWallpaper) => {
    setWallpaper(newWallpaper);
    localStorage.setItem('chatWallpaper', newWallpaper);
    setShowWallpaperModal(false);
  };

  const handleDeleteChat = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/chats/${chatId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onCloseChat();
    } catch (error) {
      console.error('Error deleting the chat!', error);
    }
    setShowDeleteConfirmation(false);
  };

  const renderMessagePost = (message) => {
    if (!message.post) return null;
    return (
        <div className="text-auto w-full mt-2">
          <div className="flex items-center mb-2">
            {message.post.user.avatar && (
                <img
                    src={message.post.user.avatar}
                    alt="User avatar"
                    className="w-10 h-10 rounded-full object-cover mr-2"
                />
            )}
            <div>
              <p className="text-sm font-semibold">{message.post.user.name}</p>
              <p className="text-xs text-auto">{new Date(message.post.created_at).toLocaleString().slice(0, -3)}</p>
            </div>
          </div>

          {message.post.content && (
              <p className="text-base mb-4">{message.post.content}</p>
          )}

          {message.post.photos && message.post.photos.length > 0 ? (
              <div className="grid grid-cols-3 ">
                {message.post.photos.map((photo, index) => (
                    <img
                        key={`${process.env.REACT_APP_BASE_URL}/posts/${message.post.id}/photos/${index}`}
                        src={photo}
                        alt="Post attachment"
                        className="w-24 h-24 object-cover rounded-lg mr-2 mb-2"
                    />
                ))}
              </div>
          ) : null}
        </div>
    );
  };

  const handleDeleteSelectedMessages = async () => {
    const token = localStorage.getItem('token');
    try {
      await Promise.all(
          selectedMessages.map((messageId) =>
              axios.delete(`${process.env.REACT_APP_API_URL}/messages/${messageId}`, {
                headers: { Authorization: `Bearer ${token}` },
              })
          )
      );
      setMessages((prevMessages) => prevMessages.filter((m) => !selectedMessages.includes(m.id)));
      setSelectedMessages([]);
    } catch (error) {
      console.error('Ошибка при удалении сообщений!', error);
    }
  };

  const handleTouchStart = (e, messageId) => {
    touchTimer.current = setTimeout(() => {
      const rect = e.target.getBoundingClientRect();
      setMessageContextMenu({
        visible: true,
        x: rect.left,
        y: rect.top,
        messageId: messageId,
      });
    }, 500);
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimer.current);
  };

  const handleCopyMessage = async (messageId) => {
    const message = messages.find((m) => m.id === messageId);
    if (message) {
      try {
        await navigator.clipboard.writeText(message.content);
        toast.success("Сообщение скопировано в буфер обмена!");
      } catch (error) {
        console.error("Ошибка при копировании сообщения:", error);
      }
    }
  };

  return (
      <div
          className={`flex flex-col h-[calc(100vh-100px)] dark:bg-neutral-800 dark:text-white rounded-lg p-2 ${width < 768 ? 'fixed bottom-0 left-0 right-0 top-0 z-50 h-full' : ''}`}>
        {loading ? (
            <Loader/>
        ) : (
            selectedMessages.length > 0 ? (
                <div
                    className="flex items-center h-20 p-4 bg-gray-100 shadow-inner mb-4 rounded-lg dark:bg-neutral-700 dark:text-white">
                  <button
                      onClick={() => {
                        setIsSelectionMode(false);
                        setSelectedMessages([]);
                      }}
                      className="bg-gray-200 px-3 py-1 rounded-full mr-3 dark:bg-neutral-600 hover:bg-gray-300 dark:hover:bg-neutral-500 transition-all duration-300"
                  >
                    <i className="fas fa-xmark text-2xl dark:text-neutral-400"></i>
                  </button>
                  <p className="flex-1 text-xl font-bold">{selectedMessages.length} выбрано</p>
                  <button onClick={handleDeleteSelectedMessages}
                          className="bg-red-200 px-3 py-1.5 rounded-full mr-3 dark:bg-red-500 hover:bg-red-300 dark:hover:bg-red-400 transition-all duration-300">
                    <i className="fas fa-trash text-xl text-red-600 dark:text-red-900"></i>
                  </button>
                  <button onClick={handleForwardSelectedMessages}
                          className="bg-gray-200 px-3 py-1.5 rounded-full dark:bg-neutral-600 hover:bg-gray-300 dark:hover:bg-neutral-500 transition-all duration-300">
                    <i className="fas fa-share text-xl dark:text-neutral-400"></i>
                  </button>
                </div>
            ) : (
                <div
                    className="flex items-center p-4 bg-gray-100 shadow-inner mb-4 rounded-lg dark:bg-neutral-700 dark:text-white">
                  {width < 768 ? (
                      <button onClick={onCloseChat}>
                        <i className="fas fa-chevron-left text-xl mr-4 dark:text-neutral-400"></i>
                      </button>
                  ) : null}
                  <img src={chatPartnerAvatar} alt="Avatar" className="w-10 h-10 rounded-full object-cover mr-4"/>
                  <div className="flex-1">
                    <Link to={`/profile/${userId}`} className="text-xl font-bold">{chatPartner}</Link>
                    <p className="ml-auto text-neutral-500 text-sm dark:text-gray-400"><OnlineStatus userId={userId}
                                                                                                     onlineStatus={onlineStatus}
                                                                                                     initialStatus={initialStatus}
                                                                                                     initialLastSeen={initialLastSeen}/>
                    </p>
                  </div>
                  <button onClick={handleThreeDotClick}>
                    <i className="fa-solid fa-ellipsis-vertical text-2xl mr-2 dark:text-neutral-400 cursor-pointer"></i>
                  </button>

                  {contextMenu.visible && (
                      <motion.div
                          className="absolute z-50 p-2 bg-white shadow-lg rounded-lg dark:bg-neutral-800 max-w-[90%] md:max-w-xs"
                          style={{
                            top: `min(${contextMenu.y}px, ${window.innerHeight - 100}px)`,
                            left: `min(${contextMenu.x}px, ${window.innerWidth - 200}px)`,
                          }}
                          ref={contextMenuRef}
                          initial={{opacity: 0, scale: 0.9}}
                          animate={{opacity: 1, scale: 1}}
                          exit={{opacity: 0, scale: 0.9, transition: {duration: 0.3}}}
                      >
                        <button
                            onClick={() => setShowWallpaperModal(true)}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-900 hover:rounded-t-lg"
                        >
                          <i className="fas fa-image mr-2"></i> Изменить обои
                        </button>
                        <button
                            onClick={() => setShowDeleteConfirmation(true)}
                            className="block w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 dark:text-red-500 dark:hover:bg-gray-100 dark:hover:text-red-500 hover:rounded-b-lg"
                        >
                          <i className="fas fa-trash mr-2"></i> Удалить чат
                        </button>
                      </motion.div>
                  )}

                  {showWallpaperModal && (
                      <WallpaperModal onClose={() => setShowWallpaperModal(false)} onChange={handleChangeWallpaper}/>
                  )}
                  {showDeleteConfirmation && (
                      <ConfirmationModal onConfirm={handleDeleteChat}
                                         onCancel={() => setShowDeleteConfirmation(false)}/>
                  )}
                </div>
            )
        )}
        <div
            className="chat-contfiner flex-1 overflow-y-auto bg-gray-100 p-4 rounded-lg shadow-inner mb-4 dark:bg-neutral-700 dark:text-white"
            ref={chatContainerRef}
            style={{
              backgroundImage: wallpaper ? `url(${wallpaper})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
        >
          {loading ? (
              <div className={`flex items-center justify-center h-full`}>
                <ClipLoader color="#6B46C1" size={75}/>
              </div>
          ) : messages.length === 0 ? (
              <NoMessage/>
          ) : (
              <ul className="space-y-2">
                {Object.keys(groupedMessages).map((date) => (
                    <React.Fragment key={date}>
                      <MessageDateSeparator date={date}/>
                      {groupedMessages[date].map((message) => (
                          <li
                              key={message.id}
                              className={`message flex flex-col ${(message.is_forwarded && message.original_sender_id !== parseInt(localStorage.getItem('user_id'))) || (!message.is_forwarded && message.sender_id !== parseInt(localStorage.getItem('user_id'))) ? 'items-start' : 'items-end'} ${message.id === highlightedMessageId && !isSelectionMode ? 'bg-neutral-200 dark:bg-neutral-600 rounded-2xl' : ''} ${selectedMessages.includes(message.id) ? 'bg-neutral-200 dark:bg-neutral-600 rounded-2xl' : ''}`}
                              onContextMenu={(e) => handleMessageContextMenu(e, message.id)}
                              onTouchStart={(e) => handleTouchStart(e, message.id)}
                              onTouchEnd={handleTouchEnd}
                              onClick={() => isSelectionMode && toggleMessageSelection(message.id)}
                          >
                            <div
                                className={`flex flex-col px-2 py-1 max-w-xl break-words ${
                                    (message.is_forwarded && message.original_sender_id !== parseInt(localStorage.getItem('user_id'))) ||
                                    (!message.is_forwarded && message.sender_id !== parseInt(localStorage.getItem('user_id')))
                                        ? 'bg-gradient-to-r from-neutral-300 to-neutral-200 text-black rounded-br-xl rounded-tl-xl rounded-tr-xl dark:bg-gray-300'
                                        : 'bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white rounded-bl-xl rounded-tr-xl rounded-tl-xl dark:bg-violet-600 '
                                }`}
                            >
                              {message.attachment && (
                                  <img
                                      src={`${process.env.REACT_APP_BASE_URL}/storage/${message.attachment}`}
                                      alt="attachment"
                                      className={`w-[350px] h-[200px] object-cover rounded-lg rounded-b-none cursor-pointer mb-1  ${width < 768 ? 'w-[250px] h-[150px]' : ''}`}
                                      onClick={() => openImageModal(`${process.env.REACT_APP_BASE_URL}/storage/${message.attachment}`)}
                                  />
                              )}
                              {Boolean(message.is_forwarded) && (
                                  <p className="text-xs">
                                    <i className="fas fa-reply mr-1"></i>Пересланное сообщение
                                    {messages.find((m) => m.id === message.sender_id)?.user?.name}
                                  </p>
                              )}
                              {message.reply_to && (
                                  <div
                                      className={`text-sm text-white mb-1 ${
                                          message.sender_id === parseInt(localStorage.getItem('user_id'))
                                              ? 'bg-neutral-400 bg-opacity-50 dark:bg-opacity-50'
                                              : 'bg-neutral-400 dark:bg-neutral-500 bg-opacity-50'
                                      } px-2 py-1 mt-2 rounded bg-opacity-50 dark:bg-opacity-50 dark:bg-gray-400 border-l-4 border-l-white`}
                                  >
                                    {messages.find((m) => m.id === message.reply_to) ? (
                                        messages.find((m) => m.id === message.reply_to).content
                                    ) : (
                                        <div className="flex items-center space-x-2 animate-pulse">
                                          <div className="w-full h-4 px-2 py-1 bg-violet-300 dark:bg-violet-500/50 rounded"></div>
                                        </div>
                                    )}
                                  </div>
                              )}

                              {renderMessagePost(message)}
                              <div className="flex justify-between">
                                <p className="mr-2">{message.content}</p>
                                {message.updated_at && message.updated_at !== message.created_at && (
                                    <span
                                        className="text-xs text-gray-400 mt-auto">{message.is_edited ? 'ред.' : ''}</span>
                                )}
                                <span
                                    className={`text-[10px] text-right block mt-auto ml-auto ${
                                        message.sender_id === parseInt(localStorage.getItem('user_id')) ? 'text-white' : 'text-gray-500'
                                    }`}
                                >
                        {formatMessageTime(message.created_at)}
                      </span>
                                {!message.is_forwarded && message.sender_id === parseInt(localStorage.getItem('user_id')) && (
                                    <span className="ml-2 text-[12px] mt-auto">
                            {message.is_read ? (
                                <svg
                                    width="800px"
                                    height="800px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-4 h-4"
                                >
                                  <path
                                      d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14"
                                      stroke="#ffffff"
                                      strokeWidth="3"
                                      strokeLinecap="round"
                                  />
                                  <path d="M16 7L12 11" stroke="#ffffff" strokeWidth="3" strokeLinecap="round"/>
                                  <path
                                      d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7"
                                      stroke="#ffffff"
                                      strokeWidth="3"
                                      strokeLinecap="round"
                                  />
                                </svg>
                            ) : (
                                <i className="fas fa-check text-white"></i>
                            )}
                        </span>
                                )}
                              </div>
                              <div className="flex space-x-2">
                                {Object.keys(message.reactions || {}).map((reaction) => (
                                    <div className="flex items-center space-x-1 mt-2 mb-2" key={reaction}>
                                      <button
                                          className="flex items-center space-x-1 text-sm bg-neutral-200 rounded-full px-2"
                                          onClick={() => handleReaction(message.id, reaction)}
                                      >
                                        <span className="text-lg">{reaction}</span>
                                        <span
                                            className="text-xs font-bold text-neutral-950">{message.reactions[reaction].length}</span>
                                      </button>
                                    </div>
                                ))}
                              </div>
                            </div>
                          </li>
                      ))}
                    </React.Fragment>
                ))}
                <div ref={messagesEndRef}/>
              </ul>
          )}
        </div>
        {messageContextMenu.visible && (
            <motion.div
                className="absolute z-50 p-2 bg-white shadow-lg rounded-lg dark:bg-neutral-800"
                style={{top: `${messageContextMenu.y}px`, left: `${messageContextMenu.x}px`}}
                ref={messageContextMenuRef}
                initial={{opacity: 0, scale: 0.9}}
                animate={controls}
                exit={{opacity: 0, scale: 0.9, transition: {duration: 0.3}}}
            >
              <div className="flex justify-between bg-neutral-200 dark:bg-neutral-700 rounded-full mb-2">
                {reactionsList.map((reaction) => (
                    <button
                        key={reaction}
                        onClick={() => {
                          handleReaction(messageContextMenu.messageId, reaction);
                          setMessageContextMenu({visible: false, x: 0, y: 0, messageId: null});
                        }}
                        className="text-xl p-2 hover:bg-neutral-300 dark:hover:bg-neutral-600 dark:text-white transition-all duration-300 rounded-full"
                    >
                      {reaction}
                    </button>
                ))}
              </div>
              <button
                  onClick={() => {
                    setIsSelectionMode(true);
                    toggleMessageSelection(messageContextMenu.messageId);
                    setMessageContextMenu({visible: false, x: 0, y: 0, messageId: null});
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-100 dark:rounded-lg dark:hover:text-gray-700 transition-all duration-300"
              >
                <i className="fas fa-check mr-2"></i> Выбрать сообщение
              </button>
              <button
                  onClick={() => {
                    handleCopyMessage(messageContextMenu.messageId);
                    setMessageContextMenu({visible: false, x: 0, y: 0, messageId: null});
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-100 dark:rounded-lg dark:hover:text-gray-700 transition-all duration-300"
              >
                <i className="fas fa-copy mr-2"></i> Скопировать
              </button>
              <button
                  onClick={() => handleForwardButtonClick(messageContextMenu.messageId)}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-100 dark:rounded-lg dark:hover:text-gray-700 transition-all duration-300"
              >
                <i className="fas fa-share mr-2"></i> Переслать
              </button>
              <button
                  onClick={() => handleReplyToMessage(messageContextMenu.messageId)}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-100 dark:rounded-lg dark:hover:text-gray-700 transition-all duration-300"
              >
                <i className="fas fa-reply mr-2"></i> Ответить
              </button>
              <button
                  onClick={() => handleEditMessage(messageContextMenu.messageId)}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-100 dark:rounded-lg dark:hover:text-gray-700 transition-all duration-300"
              >
                <i className="fas fa-edit mr-2"></i> Редактировать
              </button>
              <button
                  onClick={() => handleDeleteMessage(messageContextMenu.messageId)}
                  className="block w-full text-left px-4 py-2 text-sm text-red-500 rounded-lg hover:bg-red-600/10 dark:text-red-500 dark:hover:bg-red-600/10 dark:rounded-lg dark:hover:text-red-500 transition-all duration-300"
              >
                <i className="fas fa-trash mr-2"></i> Удалить
              </button>
            </motion.div>
        )}
        {isImageModalOpen && (
            <motion.div
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                initial={{opacity: 0, scale: 0.9}}
                animate={{opacity: 1, scale: 1}}
                exit={{opacity: 0, scale: 0.9}}
            >
              <div className="relative w-[90%] max-w-3xl">
                <img src={modalImageSrc} alt="Full view"
                     className="w-full h-full rounded-lg object-contain max-h-[80vh]"/>
                <button
                    onClick={closeImageModal}
                    className="absolute top-3 right-3 bg-violet-500 text-white p-2 rounded-full px-3.5 items-center"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </motion.div>
        )}
        {files.length > 0 && (
            <div className="flex gap-4 mt-4 overflow-x-auto">
              {files.map((file, index) => (
                  <div key={index} className="relative group">
                    <img
                        src={URL.createObjectURL(file)}
                        alt="preview"
                        className="max-w-xs max-h-32 rounded-lg shadow-lg border border-gray-200 dark:border-neutral-700 transition duration-300 ease-in-out group-hover:filter group-hover:brightness-50 mb-4"
                    />
                    <button
                        onClick={() => handleRemoveFile(index)}
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-opacity-60 text-white px-2 py-0.5 items-center rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out hover:bg-neutral-700"
                    >
                      <i className="fas fa-times text-3xl"></i>
                    </button>
                  </div>
              ))}
            </div>
        )}
        <form onSubmit={handleSubmit}
              className="flex items-center space-x-2 md:space-x-3 bg-gray-100 dark:bg-neutral-700 p-2 rounded-full shadow-inner transition duration-300 w-full relative">
          <label
              className="flex items-center justify-center w-9 h-9 md:w-10 md:h-10 bg-violet-600 text-white rounded-full shadow-lg hover:shadow-xl hover:bg-violet-700 transition duration-200 cursor-pointer">
            <i className="fas fa-paperclip"></i>
            <input type="file" accept="image/*" onChange={handleFileChange} className="hidden"/>
          </label>

          <textarea
              onKeyDown={handleKeyDown}
              ref={textareaRef}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="flex-grow py-2 text-sm md:text-base border-none rounded-full text-neutral-800 dark:text-neutral-200 bg-transparent placeholder-neutral-500 dark:placeholder:text-neutral-400 focus:outline-none focus:ring-0 resize-none overflow-hidden"
              placeholder="Сообщение"
              rows={1}
              style={{minHeight: '40px', maxHeight: '100px'}}
          />

          {replyToMessage && (
              <div
                  className="absolute -top-20 -left-3 right-0 bg-gray-100 dark:bg-neutral-800 p-4 rounded-lg flex items-center shadow animate-slideIn transition duration-300 border border-gray-200 dark:border-neutral-700">
                <i className="fas fa-reply text-violet-500 mr-2"></i>
                <div className="flex-grow text-xs text-gray-500 dark:text-gray-300 border-l-4 border-l-violet-500 pl-2">
                  <span className="font-semibold text-gray-700 dark:text-white">{replyToMessage.sender.name}</span>
                  <div>{replyToMessage.content}</div>
                </div>
                <button type="button" onClick={() => setReplyToMessage(null)}
                        className="text-neutral-500 dark:text-neutral-400 hover:text-violet-500 dark:hover:text-violet-400 transition duration-200">
                  <i className="fas fa-times"></i>
                </button>
              </div>
          )}
          {editMessageId && (
              <button
                  type="button"
                  onClick={handleCancelEdit}
                  className="bg-neutral-200 dark:bg-neutral-800 w-9 h-9 md:w-10 md:h-10 rounded-full shadow-inner hover:shadow-xl dark:text-neutral-500 hover:text-violet-500 dark:hover:text-violet-400 transition-all duration-200"
              >
                <i className="fas fa-times text-xl"></i>
              </button>
          )}

          <button
              type="button"
              onClick={toggleEmojiPicker}
              className="flex items-center justify-center w-9 h-9 md:w-10 md:h-10 text-neutral-500 dark:text-neutral-400 hover:text-violet-500 transition duration-200"
              ref={emojiButtonRef}
          >
            <i className="fa-regular fa-face-smile text-xl"></i>
          </button>

          {showEmojiPicker && (
              <div className="absolute bottom-16 right-0 z-10" ref={emojiPickerRef}>
                <Picker
                    data={data}
                    onEmojiSelect={addEmoji}
                    set="apple"
                    locale="ru"
                    previewPosition="none"
                    navPosition="bottom"
                    theme="auto"
                />
              </div>
          )}

          <button
              type="submit"
              className="flex items-center justify-center w-9 h-9 md:w-10 md:h-10 bg-violet-600 text-white rounded-full shadow-lg hover:shadow-xl hover:bg-violet-700 transition duration-300"
          >
            <i className="fas fa-arrow-up text-sm md:text-base"></i>
          </button>
        </form>

        <ForwardMessageModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            onForward={handleForwardMessage}
            onForwardAll={handleForwardSelectedMessages}
        />
      </div>
  );
}

export default Chat;