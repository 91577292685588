import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const wallpapers = [
    '/bg/gr1962_1920_1080.png',
    '/bg/gr1951_1920_1080.png',
    '/bg/gr1923_1920_1080.png',
    '/bg/gr1922_1920_1080.png',
    '/bg/gr1915_1920_1080.png',
    '/bg/gr1943_1920_1080.png',
    '/bg/gr1877_1920_1080.png',
    '/bg/gr1755_1920_1080.png',
    '/bg/gr1822_1920_1080.png',
    '/bg/gr1245_1920_1080.png',
];

function WallpaperModal({ onClose, onChange }) {

    const handleWallpaperClick = (wallpaper) => {
        onChange(wallpaper);
        onClose();
    };

    const resetToDefault = () => {
        onChange(null);
        onClose();
    };

    return (
        <AnimatePresence>
                <motion.div
                    className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 backdrop-blur-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <motion.div
                        className="relative bg-white/80 dark:bg-neutral-800/80 rounded-2xl p-6 w-[90%] max-w-xl shadow-xl backdrop-blur-lg"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.4, ease: 'easeInOut' }}
                    >
                        <h2 className="text-xl font-bold text-gray-800 dark:text-gray-200 text-center mb-4">
                            Выберите обои
                        </h2>

                        <div className="grid grid-cols-4 gap-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 mb-6">
                            {wallpapers.map((wallpaper, index) => (
                                <motion.div
                                    key={index}
                                    className="relative overflow-hidden rounded-xl shadow-md cursor-pointer"
                                    whileHover={{ scale: 1.05 }}
                                    transition={{ duration: 0.3 }}
                                    onClick={() => handleWallpaperClick(wallpaper)}
                                >
                                    <img
                                        src={wallpaper}
                                        alt={`Wallpaper ${index + 1}`}
                                        className="object-cover w-full h-24 sm:h-32 md:h-40 lg:h-48"
                                    />
                                </motion.div>
                            ))}
                        </div>

                        <div className="flex justify-between">
                            <button
                                onClick={resetToDefault}
                                className="w-full px-4 py-2 text-red-500 border border-transparent hover:bg-red-100 dark:hover:bg-red-600/10 rounded-lg transition-colors"
                            >
                                Сбросить
                            </button>
                            <button
                                onClick={onClose}
                                className="ml-3 w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition-colors"
                            >
                                Отмена
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
        </AnimatePresence>
    );
}

export default WallpaperModal;
