import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function QrLoginMobile() {
    const [scanResult, setScanResult] = useState(null);
    const navigate = useNavigate();

    const handleScan = async (data) => {
        if (data) {
            const token = data.split('/').pop(); // Получаем только токен
            setScanResult(token);
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/qr/confirm`,
                    { token },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
                if (response.data.message === 'QR session confirmed') {
                    toast.success('Сессия подтверждена');
                    navigate('/');
                }
            } catch (error) {
                console.error(error);
                toast.error('Ошибка подтверждения QR-кода');
            }
        }
    };



    const handleError = (error) => {
        console.error('Ошибка при сканировании', error);
        toast.error('Ошибка сканирования');
    };

    return (
        <div className="qr-reader-container">
            <h2 className="text-2xl font-semibold mb-4">Сканирование QR-кода</h2>
            <QrReader
                constraints={{ facingMode: 'environment' }} // Используем заднюю камеру
                delay={300}
                onResult={(result) => {
                    if (result?.text) {
                        handleScan(result.text);
                    }
                }}
                onError={(error) => {
                    console.error('Ошибка при сканировании:', error);
                }}
                style={{ width: '100%' }}
            />

            {scanResult && <p>Результат сканирования: {scanResult}</p>}
        </div>
    );
}

export default QrLoginMobile;
