import React from 'react';
import { motion } from 'framer-motion';

function NoMessage() {
    return (
        <div className="flex flex-col items-center justify-center h-full space-y-4">
            <div className="relative w-52 h-52 mb-6">
                <img
                    src="/chat.png"
                    alt="Start Chat"
                    className="w-full h-full object-cover rounded-md transform transition-transform hover:rotate-6 hover:scale-105"
                />
            </div>
            <motion.p
                className="text-center text-base font-medium text-gray-800 dark:text-gray-200 bg-gradient-to-r from-violet-500 via-indigo-500 to-blue-500 text-transparent bg-clip-text"
                initial={{ opacity: 0, y: 20 }}  // Начальное состояние
                animate={{ opacity: 1, y: 0 }}   // Конечное состояние
                transition={{ duration: 0.5 }}    // Время анимации
            >
                Сообщений нет. Начните общение первым!
            </motion.p>
        </div>
    );
}

export default NoMessage;
